

<template>
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">

          <div class="modal-header">
            <h3>
                Marcar asistencia en:
            </h3>
          </div>  

          <div class="modal-body">
            <div class="links-list--wrapper">
                <ul class="links-list">
                    <li class="link--container" v-for="item in linksList" :key="item.id">
                        <div class="browser-logo">
                            <img 
                                :src="`${item.logo}`"
                                alt="Browser logo"
                            />
                        </div>
                        <div class="browser-name">
                            <span>{{ item.name }}</span>
                        </div>
                        <div class="button--container">
                            <button
                                class="btn btn-primary button-link"
                                @click="openLink($event, item)"
                            >
                                {{item.type === 'app' ? 'Abrir' : 'Continuar'}}
                            </button>
                            <!-- <button class="modal-default-button" @click="openLink($event)">
                                OK
                            </button> -->
                        </div>
                    </li>
                </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
</template> 
<script>
    export default {
        data(){
            return{
                linksList: [],
                appId: '',
                appURL: ''
            }
        },
        emits: ['close'],
        props: {
            vendor: {
                type: String,
                default: '',
            },
            urlParams: {
                type: Object,
                default: () => {},
            },
            isMobile: {
                type: Boolean,
                default: false,
            },
            os: {
                type: String,
                default: ''
            }
        },
        methods:{
            getLinks(){

                this.linksList = [
                    {
                        id: 1,
                        name: `${process.env.VUE_APP_FRONT_BASE} App`,
                        url: this.appURL,
                        type: 'app',
                        logo: `images/experience-app-logo.png`
                    },
                    {
                        id: 2,
                        name: 'Chrome',
                        url: 'https://linkedin.com',
                        type: 'browser',
                        logo: 'images/globe.png'
                    }
                ]
            },
            openLink($event, item){
                console.log(item.type)
                if(item.type === 'app')
                    window.location.href = item.url

                this.$emit('close')
            }
        },
        mounted(){
            const occurrenceId = this.urlParams.occurrenceId
            const classroomCode = this.urlParams.classroomCode
            const time = this.urlParams.time
            const date = this.urlParams.date
            const expired = this.urlParams.expired

            const defaultAppId     = this.os === 'ios' ? "com.uplanner.u-experience.dev" : "com.upl.uexperience.dev"
            const envAppId         = this.os === 'ios' ? process.env.VUE_APP_ID_IOS : process.env.VUE_APP_ID_ANDROID
            const isDev            = process.env.VUE_APP_ENV_TYPE === 'test'

            this.appId = isDev ? defaultAppId : envAppId
            this.appURL = `${this.appId}://qr_scan?occurrenceId=${occurrenceId}&classroomCode=${classroomCode}&time=${time}&date=${date}&expired=${expired}`
            this.getLinks()
        }
    }
</script>
<style>
    :root{
        --dark-teal: #07808D;
    }

    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        max-width: 420px;
        width: 100%;
        bottom: 0;
        position: fixed;
        left: 50%;
        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        margin: 0px auto;
        padding: 15px 19px;
        background-color: #fff;
        border-radius: 12px 12px 0 0;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
        font-family: Helvetica, Arial, sans-serif;
    }

    .modal-header h3 {
        margin-top: 0;
        color: var(--dark-teal);
        font-weight: 400;
        font-size: 17px;
    }

    .modal-body {
        margin: 20px 0;
    }

    .modal-default-button {
        float: right;
    }

    .links-list .link--container{
        display: flex;
        align-items: center;
    }

    .links-list > li + li{
        padding-top: 20px;
    }

    .links-list .link--container .browser-logo img{
        width: 35px;
        height: 35px;
        object-fit: contain;
    }

    .links-list .link--container .browser-name{
        color: var(--dark-teal);
        font-size: 17px;
        padding: 0 10px;
        flex: 1;
    }

    .links-list .link--container .button-link{
        background:var(--dark-teal);
        border: 1px solid var(--dark-teal);
        border-radius: 3px;
        color: #fff;
        font-size: 16px;
        padding: 8px;
        width: 97px;
        transition: all 0.3s;
    }

    .links-list .link--container .button-link:hover{
        color: var(--dark-teal);
        background-color: #fff;
    }
    
    /*
    * Styles for transition="modal"
    */

    .modal-enter-from, .modal-leave-to {
        opacity: 0;
    }

    .modal-enter-active .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: translate(-50%, 100%);
        transform: translate(-50%, 100%);
    }

</style>