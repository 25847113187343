import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

export function createSentry(app) {
    const config = {
        app,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        integrations: [
            new Integrations.BrowserTracing({ tracingOrigins: ["*"] })
        ],
        tracesSampleRate: 1.0,
        release: process.env.VUE_APP_SENTRY_CONFIG_RELEASE,
        tags: {
            app: process.env.VUE_APP_SENTRY_TAG_APP,
            environment: process.env.VUE_APP_SENTRY_TAG_ENVIRONMENT,
        },
    };

    Sentry.init(config);
    app.config.errorHandler = (error, vm, info) => {
        Sentry.withScope((scope) => {
            scope.setExtras(info);
            scope.setTag("origin", "QR");
            Sentry.captureException(error);
        });
    };
}

